<template>
  <div>
    <b-container class="mt-4">
      <b-form @submit="sendGroup">
        <b-card header-class="header">
          <template #header>
            <label v-text="'Yarışma Grubu Oluştur'" class="mr-2 text-white"></label>
          </template>
          <b-row>
            <b-col cols="3">
              <b-select v-model="selectParticipantType" :options="participantType" required></b-select>
            </b-col>
            <b-col v-if="selectedParticipantType == 'trainerState'">
              <b-row>
                <b-col cols="4">
                  <b-select :options="clubMembers" text-field="title" value-field="value" v-model="items[0].personelId1"
                    required></b-select>
                </b-col>
                <b-col>
                  <b-button variant="primary" type="submit">Gönder</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <div v-if="selectedParticipantType == 'sportState'">
            <b-table :fields="getFields()" :items="items">
              <template #cell(category)="row">
                <b-select :options="getCategorySelections" text-field="title" value-field="id"
                  v-model="items[row.index].category" required></b-select>
              </template>
              <template #cell(boatClass)="row" v-if="getBranchName() !== 'Rafting'">
                <b-select :options="getBoatClassSelections" text-field="title" value-field="id"
                  v-model="items[row.index].boatType" required></b-select>
              </template>
              <template #cell(gender)="row" v-if="getBranchName() === 'Rafting'">
                <b-select :options="getGenderOptions" text-field="text" value-field="value"
                  v-model="items[row.index].gender" required></b-select>
              </template>
              <template #cell(distance)="row">
                <b-select :options="getDistanceSelections" text-field="title" value-field="id"
                  v-model="items[row.index].distance" required></b-select>
              </template>
              <template #cell(personel)="row">
                <b-row class="mb-2">
                  <b-select :options="getClubMembers(row.index)" text-field="title" :key="row.index" value-field="value"
                    v-model="items[row.index].personelId1" required></b-select>
                </b-row>
                <b-row class="mb-2" v-if="checkBoatType(row.index) > 1">
                  <b-select :options="getClubMembers(row.index)" text-field="title" :key="row.index" required
                    value-field="value" v-model="items[row.index].personelId2"></b-select>
                </b-row>
                <b-row class="mb-2" v-if="checkBoatType(row.index) > 2">
                  <b-select :options="getClubMembers(row.index)" text-field="title" :key="row.index" required
                    value-field="value" v-model="items[row.index].personelId3"></b-select>
                </b-row>
                <b-row class="mb-2" v-if="checkBoatType(row.index) > 2">
                  <b-select :options="getClubMembers(row.index)" text-field="title" :key="row.index" required
                    value-field="value" v-model="items[row.index].personelId4"></b-select>
                </b-row>
                <div v-if="checkBoatType(row.index) > 4">
                  <strong>Yedekler</strong>
                  <b-row class="mb-2">
                    <b-select :options="getClubMembers(row.index)" text-field="title" :key="row.index" value-field="value"
                      v-model="items[row.index].personelIdBackup"></b-select>
                  </b-row>
                  <b-row class="mb-2" v-if="checkGender(row.index) === 'mixed'">
                    <b-select :options="getClubMembers(row.index)" text-field="title" :key="row.index" value-field="value"
                      v-model="items[row.index].personelIdBackup2"></b-select>
                  </b-row>
                </div>
              </template>
            </b-table>
            <b-row>
              <b-button @click="addNewGroup" style="margin-left: 2.5rem">Ekle</b-button>
              <b-button @click="popGroup" class="ml-2" variant="danger">Sil</b-button>
              <b-button class="ml-2" variant="primary" type="submit">Seçilen Grupları Gönder</b-button>
            </b-row>
          </div>
        </b-card>
      </b-form>
    </b-container>
  </div>
</template>


<script>
import participantType from "../../../store/constants/participantType";
import { mapGetters } from "vuex";
import selectionConstants from "@/store/constants/selections";
import { genderOptions } from "@/store/constants/personel";
export default {
  data() {
    return {
      participantType,
      selectedParticipantType: "",
      items: [],
    };
  },
  mounted() {
    this.$store.dispatch("initManagement");
    this.$store.dispatch("initSingleCompetition", this.$route.params.id);
    this.$store.commit("setClubCompetitionPersonel", []);
  },
  computed: {
    ...mapGetters({
      getSelections: "getManagementItems",
      clubMembers: "getClubCompetitionPersonel",
      singleCompetition: "getSingleCompetition",
    }),
    getGenderOptions: {
      get() {
        return genderOptions.concat([{ text: "Mix", value: "mixed" }]);
      },
    },

    fields: {
      get() {
        return [
          { key: "category", label: "Kategori" },
          { key: "boatClass", label: "Tekne Sınıfı" },
          {
            key: "distance",
            label: this.getBranchName() === "Rafting" ? "Disiplin" : "Mesafe",
          },
          { key: "gender", label: "Cinsiyet" },
          { key: "personel", label: "Personel" },
        ];
      },
    },
    selectParticipantType: {
      get() {
        return this.selectedParticipantType;
      },
      set(value) {
        this.selectedParticipantType = value;
        if (value == "trainerState") {
          this.items = [];
          this.addNewTrainerField();
        } else {
          this.items = [];
          this.addNewGroup();
        }
      },
    },
    getBoatClassSelections: {
      get() {
        return [{ title: "Seçin", id: "", option: 0 }].concat(
          this.getSelections.filter((e) => {
            return e.category == selectionConstants.BOAT_CLASS; //BOAT FILTER
          })
        );
      },
    },
    getCategorySelections: {
      get() {
        return [{ title: "Seçin", id: "" }].concat(
          this.getSelections
            .filter((e) => {
              return e.category == selectionConstants.CATEGORY; //CATEGORY FILTER
            })
            .filter((el) => {
              return this.singleCompetition.category
                .split(";")
                .includes(String(el.id));
            })
        );
      },
    },
    getDistanceSelections: {
      get() {
        const specials = [];
        return [{ title: "Seçin", id: "" }].concat(
          this.getSelections
            .map((e) => {
              if (
                e.category == selectionConstants.DISTANCE &&
                e.option == this.singleCompetition.branch
              ) {
                specials.push(`${e.option}`);
              }
              return e;
            })
            .filter((e) => {
              return (
                e.category == selectionConstants.DISTANCE &&
                e.option ==
                (specials.includes(this.singleCompetition.branch)
                  ? this.singleCompetition.branch
                  : undefined)
              ); //DISTANCE FILTER
            })
        );
      },
    },
  },
  methods: {
    getFields() {
      return this.fields.filter(
        (field) =>
          !(this.getBranchName() === "Rafting" && field.key === "boatClass") !=
          (this.getBranchName() !== "Rafting" && field.key === "gender")
      );
    },
    getBranchName() {
      const branch = this.singleCompetition.branch;
      const managementItems = this.getSelections;
      return managementItems.find((item) => item.id == branch)?.title;
    },
    addNewGroup() {
      this.$store.dispatch("initClubCompetitionPersonel", {
        role: this.selectedParticipantType,
        clubId: this.$store.getters.getUser.id,
        competitionId: this.$route.params.id,
      });

      const boatType =
        this.getBranchName() === "Rafting"
          ? this.getSelections.find((item) => item.title === "4+1").id
          : "";

      this.items.push({
        boatType,
        distance: "",
        category: "",
        competitionId: this.$route.params.id,
        clubId: this.$store.getters.getUser.id,
        personelId1: null,
        personelId2: null,
        personelId3: null,
        personelId4: null,
        personelIdBackup: null,
        personelIdBackup2: null,
        formOfParticipation: "sportState",
        gender: "",
      });
    },
    addNewTrainerField() {
      this.$store.dispatch("initClubCompetitionPersonel", {
        role: "trainerState",
        clubId: this.$store.getters.getUser.id,
        competitionId: this.$route.params.id,
      });
      this.items.push({
        index: this.items.length,
        personelId1: null,
        formOfParticipation: "trainerState",
        competitionId: this.$route.params.id,
        clubId: this.$store.getters.getUser.id,
      });
    },
    popGroup() {
      this.items.pop();
    },
    sendGroup(event) {
      event.preventDefault();
      if (this.checkSportDuplicate()) {
        this.$store.dispatch("notification/setNotifications", [
          {
            title: "Hata!",
            body: "Bir tekneye aynı kişiyi birden fazla kez ekleyemezsiniz!",
            type: "danger",
          },
        ]);
      } else if (!this.checkSportGenderValidity()) {
        this.$store.dispatch("notification/setNotifications", [
          {
            title: "Hata!",
            body: "Mix cinsiyet seçiliyken teknedeki tüm yarışmacılar aynı cinsiyetten olamaz!",
            type: "danger",
          },
        ]);
      } else {
        this.$store.dispatch("addCompetitionGroup", {
          clubId: this.$store.getters.getUser.id,
          items: this.items,
        });
        this.$router.push({
          name: "ClubCompetitionGroup",
          params: {
            clubId: this.$store.getters.getUser.id,
            competitionId: this.$route.params.id,
          },
        });
      }
    },
    checkSportGenderValidity() {
      return this.items.every((e) => {
        const genders = new Set();
        for (let i = 1; i < 5; i++) {
          if (e["personelId" + i]) {
            console.log(genderOptions, this.clubMembers);
            for(const gender of genderOptions) {
              const found = this.clubMembers.find((cm) => cm.label === gender.text && cm.options.find((cmo) => cmo.value === e["personelId" + i]));
              if(found){
                genders.add(found.label);
              }
            }
          }
        }
        console.log(genders);
        return (e.gender === "mixed" && genders.size === 2) || e.gender !== "mixed";
      });
    },
    checkSportDuplicate() {
      return !this.items.every((e) => {
        const personels = [];
        for (let i = 1; i < 5; i++) {
          if (e["personelId" + i]) {
            personels.push(e["personelId" + i]);
          }
        }
        if (e["personelIdBackup"]) {
          personels.push(e["personelIdBackup"]);
        }
        if (e["personelIdBackup2"]) {
          personels.push(e["personelIdBackup2"]);
        }
        return personels.length == new Set(personels).size;
      });
    },
    checkBoatType(index) {
      return this.getBoatClassSelections.filter((e) => {
        return e.id == this.items[index].boatType;
      })[0].option;
    },
    checkGender(index) {
      return this.getGenderOptions.filter((e) => {
        return e.value == this.items[index].gender;
      })[0].value;
    },
    getClubMembers(index) {
      if (this.getBranchName() !== "Rafting") {
        return this.clubMembers;
      }
      return this.clubMembers.filter((cm) => {
        return (
          this.items[index].gender === "mixed" ||
          cm.title === "Seçin" ||
          this.getGenderOptions.find((go) => go.text === cm.label).value ===
          this.items[index].gender
        );
      });
    },
  },
};
</script>


<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(180deg,
      rgb(108, 117, 125) 10%,
      rgba(53, 57, 61, 1) 85%);
}
</style>